<template>
	<section>
		<h2>จัดการข้อมูลการรับประกัน</h2>

		<div class="folder">
			<div class="title">
				<div class="tab">
					<button class="btn-category active">การรับประกัน</button>
				</div>
				<div class="operation">
					<button @click="exportExcel()">
						<b-icon icon="download"></b-icon>
					</button>
				</div>	
			</div>

			<div class="text-center mb-3">
				<button class="btn btn-main btn-add-new" @click="openWarrantyForm(null)">เพิ่มการรับประกัน</button>
			</div>
			
			<seller-product-manage-filter class="mb-3" :optionFilter="optionFilter" @changeFilter="changeFilter($event)"></seller-product-manage-filter>

			<template v-if="isResultReady">
				<div class="vue-dragscoll" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
					<table>
						<thead>
							<tr>
								<th class="action-colummn"></th>
								<th>ประเภท</th>
								<th>เงื่อนไข</th>
								<th class="text-center">รายการสินค้า</th>
								<th class="action-colummn"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in resultList" :key="index">
								<td class="text-center">
									<button @click="openWarrantyForm(item)" class="btn btn-sm square btn-warning">แก้ไข</button>
								</td>
								<td>{{ item.name }}</td>
								<td >
									<span>{{ item.description }}</span>
								</td>
								<td class="text-center">
									<button class="btn btn-sm square btn-outline-danger" @click="openIntegratedProduct(item)">สินค้าร่วมรายการ ({{ item.productIntegrated.length }})</button>
								</td>
								<td class="text-center">
									<button @click="deleteWarranty(item)" class="btn btn-sm square btn-danger">ลบ</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</template>

			<div class="no-data" v-if="isResultReady && resultList.length === 0">
				<b-icon icon="box-seam"></b-icon>
				<span>ไม่มีรายการรับประกัน</span>
			</div>
		</div>

		<modal-warranty
            @close="closeWarrantyForm($event)"
            :editData="selectedWarranty"
			:optionType="optionWarranty"
            :isDisplay="isOpenWarrantyForm">
        </modal-warranty>

		<modal-integrated-product
            @close="closeIntegratedProduct()"
			@reloadContent="reloadContent($event)"
            :title="warrantyForIntegration.title"
			:description="warrantyForIntegration.description"
			:products="warrantyForIntegration.products"
			:keyForIntegration="warrantyForIntegration.id"
			typeForIntegration="warranty"
            :isDisplay="isOpenIntegratedProduct">
        </modal-integrated-product>
	</section>
</template>

<script>
import MixinDragScroll from '@/mixins/dragScroll';
import SellerProductManageFilter from '@/components/seller/stocks/ProductManageFilter';
import ModalWarranty from '@/components/seller/stocks/ModalWarranty';
import ModalIntegratedProduct from '@/components/seller/stocks/ModalIntegratedProduct';
import MasterService from '@/services/masterService';
import SellerService from '@/services/sellerService';
import Helper from '@/services/helper';
import RouterPath from '@/router/path';

export default {
    mixins: [ MixinDragScroll ],
	components: {
		SellerProductManageFilter,
		ModalWarranty,
		ModalIntegratedProduct
	},
	data() {
		return {
			isOpenWarrantyForm: false,
			isOpenIntegratedProduct: false,
			selectedType: 'all',
			selectedWarranty: null,
			warrantyForIntegration: {
				id: 0,
				title: '',
				description: '',
				products: null,
				data: null
			},
			optionFilter: [],
			optionWarranty: [],
			isResultReady: false,
			resultList: []
		}
	},
	mounted() {
		this.initWarrantyList();
		this.getStockWarranty();
	},
	methods: {
		openWarrantyForm(warranty) {
			this.selectedWarranty = warranty;
            this.isOpenWarrantyForm = true;
        },
        closeWarrantyForm(data) {
            this.isOpenWarrantyForm = false;

            if (data.isReload) {
                this.getStockWarranty();
            }
        },
		openIntegratedProduct(warranty) {
			this.setupWarrantyIntegration(warranty);

			this.isOpenIntegratedProduct = true;
		},
		closeIntegratedProduct() {
			this.isOpenIntegratedProduct = false;
		},
		setupWarrantyIntegration(warranty) {
			this.warrantyForIntegration = {
				id: warranty.id,
				title: warranty.name,
				description: warranty.description,
				products: warranty.productIntegrated,
				data: warranty
			};
		},
		async initWarrantyList() {
			const result = await MasterService.masterGetWarrantyList(this.selectedCategory);
			const options = [];

			options.unshift({
				id: 'all',
				name: 'แสดงทั้งหมด'
			});

            result.data.forEach((item) => {
                options.push({
                    id: item.id,
                    name: item.name
                });
            });

			this.optionFilter = options;
			this.optionWarranty = options.filter(x => x.id !== 'all');
		},
		async getStockWarranty() {
			let loader = this.$modalLoader.render();
	
            const result = await SellerService.getStockWarranty(this.selectedType === 'all' ? '' : this.selectedType);

			if (result.status == 401) {
				this.$router.push(RouterPath.SELLER_LOGIN);
			}

			this.isResultReady = true;
			this.resultList = result.data.resultList;

            loader.hide();
		},
		async reloadContent(callback) {
			await this.getStockWarranty();
			const currentWarranty = this.resultList.find(x => x.id === this.warrantyForIntegration.id);
			this.setupWarrantyIntegration(currentWarranty);
			callback();
		},
		changeFilter(type) {
			if (type) {
				this.selectedType = type;

				this.getStockWarranty();
			}
		},
		deleteWarranty(item) {
			this.$swal(
                Helper.confirmAlert('ยืนยันการลบข้อมูลการรับประกัน', 'โปรดยืนยันว่าท่านต้องการลบข้อมูลการรับประกัน "' + item.name + '" ออกจากรายการ')
            ).then(async (confirm) => {
                if (confirm.isConfirmed) {
                    let loader = this.$modalLoader.render();
					
					await SellerService.deleteWarranty(item.id);
					this.getStockWarranty();

					loader.hide();
                }
            });
		},
		async exportExcel() {
            let loader = this.$modalLoader.render();
			const result = await SellerService.exportProductIntegration('warranty');
			loader.hide();

			if (result.data?.success) {
                Helper.fileDownloadByApi(result.data?.file);
			} else {
				this.$swal(Helper.warningAlert('ไม่สามารถดาวน์โหลดไฟล์ Excel ได้', result.data?.message));
			}
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/ProductManage.scss';
</style>